import { Button, Text, tokens } from "@fluentui/react-components"
import { AlertRegular, MailRegular, AttachRegular } from "@fluentui/react-icons"
import styled from "styled-components"
import { useExpress365Dispatch, useExpress365Selector } from "../store/store"
import { OutlookMessage } from "../store/MessageReducer"
import { BusinessModuleHierarchy } from "../components/BusinessModuleHierarchy"
import { ListButton } from "../components/ListButton"
import { showNotification } from "../store/NotificationReducer"
import { SolutionRow } from "../components/SolutionRow"
import { useState, useEffect } from "react"
import { ErrorType } from "../model/ErrorType"

export function Home() {
    const dispatch = useExpress365Dispatch()
    const { selectedMessages } = useExpress365Selector((state) => state.message)

    /* @todo: MBE Remove before release */
    const [keyPressCount, setKeyPressCount] = useState(0)
    const [showContent, setShowContent] = useState(false)
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "a" || event.key === "A") {
                setKeyPressCount((prevCount: number) => prevCount + 1)
            } else if (event.key === "s" || event.key === "S") {
                setKeyPressCount(0)
            }
        }

        window.addEventListener("keydown", handleKeyPress)

        return () => {
            window.removeEventListener("keydown", handleKeyPress)
        }
    }, [])
    useEffect(() => {
        if (keyPressCount === 3) {
            setShowContent(true)
        } else if (keyPressCount === 0) {
            setShowContent(false)
        }
    }, [keyPressCount])

    // @todo: MBE Remove before release
    const [criticalError, setCriticalError] = useState(false)
    if (criticalError) {
        throw new Error(
            JSON.stringify({
                type: ErrorType.Critical,
                message: "Oops! Something went wrong",
                links: [
                    {
                        text: "Solution Not Available",
                        url: ""
                    }
                ]
            })
        )
    }

    return (
        <PageContainer style={{ backgroundColor: tokens.colorNeutralBackground1 }}>
            <SolutionRow />
            <BusinessModuleHierarchy />
            {/* @todo: MBE Remove before release */}
            {showContent && (
                <>
                    <Text weight="bold" style={{ paddingLeft: "10px", paddingTop: "20px" }}>
                        Debug tools
                    </Text>
                    <ListButton
                        icon={<AlertRegular />}
                        style={{ borderWidth: "0px", justifyContent: "left" }}
                        onClick={() => {
                            setCriticalError(true)
                        }}
                    >
                        Test Critical Error
                    </ListButton>
                    <ListButton
                        icon={<AlertRegular />}
                        style={{ borderWidth: "0px", justifyContent: "left" }}
                        onClick={() => {
                            dispatch({ type: "RESET_STATE" })
                        }}
                    >
                        Reset API state
                    </ListButton>

                    {selectedMessages &&
                        selectedMessages.length > 0 &&
                        selectedMessages.map((message: OutlookMessage) => (
                            <ListButton
                                key={message.itemId}
                                onClick={() => {
                                    dispatch(
                                        showNotification({
                                            message: message.subject,
                                            intent: "warning"
                                        })
                                    )
                                }}
                                icon={<MailRegular />}
                                style={{
                                    borderWidth: "0px",
                                    justifyContent: "left"
                                }}
                            >
                                {message.subject}{" "}
                                {message.hasAttachments?.toString() === "true" && <AttachRegular />}
                            </ListButton>
                        ))}
                </>
            )}
        </PageContainer>
    )
}

export const IconButton = styled(Button)`
    border-width: 0px;
`

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100vh;
    background-color: ${tokens.colorNeutralBackground3};
`
