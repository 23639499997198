import {
    Avatar,
    createTableColumn,
    TableCellLayout,
    TableColumnDefinition,
    Text,
    tokens
} from "@fluentui/react-components"
import { Apps24Regular, SearchRegular } from "@fluentui/react-icons"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { BreadcrumbWithBack } from "../components/BreadcrumbWithBack"
import { Paths } from "../constants/routing"
import { useExpress365Dispatch } from "../store/store"
import { IconButton, PageContainer } from "./Home"
import { useGetSolutionsQuery } from "../service/WorkPointWebApi"
import { useIntl } from "react-intl"
import { TableDisplay } from "../components/TableDisplay"
import { selectSolution } from "../store/SolutionReducer"
import { FlexColumn, FlexRow } from "../components/ProcessDrawer"
import { AnimatedSearchBar } from "../components/AnimatedSearchBar"
import { ActionsContainer, HeaderTitleText } from "./BusinessModulePage"
import { ErrorType, ExpressError } from "../model/ErrorType"

export const SolutionsPage = () => {
    const intl = useIntl()
    const [items, setItems] = useState<any[]>([])
    const [searchTerm, setSearchTerm] = useState("")

    const { data, isFetching } = useGetSolutionsQuery(undefined, {
        //skip: !wpApiReady
    })
    const dispatch = useExpress365Dispatch()
    const navigate = useNavigate()

    const [showSearchBar, setShowSearchBar] = useState(false)
    const toggleSearchBar = () => {
        setShowSearchBar((prev) => !prev)
    }

    useEffect(() => {
        if (!isFetching && data) {
            if (data.length === 0) {
                throw new Error(
                    JSON.stringify({
                        type: ErrorType.Solution,
                        message: intl.formatMessage({
                            id: "noSolutionsFound",
                            defaultMessage: "⚠️ No WorkPoint Solutions was found on this Tenant."
                        }),
                        description: intl.formatMessage({
                            id: "experiencePower",
                            defaultMessage:
                                "Experience the power of WorkPoint 365 and unlock your organization's full potential today."
                        }),
                        links: [
                            {
                                text: intl.formatMessage({
                                    id: "learnMore",
                                    defaultMessage: "Learn More"
                                }),
                                url: "https://workpoint365.com/"
                            }
                        ]
                    } as ExpressError)
                )
            }
        }
    }, [data, dispatch, intl, isFetching])

    useEffect(() => {
        if (!isFetching && data && data.length > 0) {
            const results = data.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            setItems(results)
        }
    }, [searchTerm, data, isFetching])

    const columnsDef: TableColumnDefinition<any>[] = [
        createTableColumn<any>({
            columnId: "name",
            renderHeaderCell: () => (
                <Text weight="semibold">
                    {intl.formatMessage({
                        defaultMessage: "Solution Name",
                        id: "solutionName"
                    })}
                </Text>
            ),
            renderCell: (item) => {
                // const urlParts = item.url.split("/sites/")

                const lowerCaseUrl = item.url.toLowerCase()
                const searchWord = lowerCaseUrl.includes("/sites/")
                    ? "/sites/"
                    : lowerCaseUrl.includes("/teams/")
                      ? "/teams/"
                      : undefined

                let urlParts
                if (searchWord) {
                    const index = item.url.indexOf(searchWord)
                    urlParts = [item.url.substring(0, index), item.url.substring(index)]
                }

                return (
                    <TableCellLayout
                        media={
                            <Avatar
                                color="colorful"
                                name={item.name}
                                style={{ borderRadius: "0px" }}
                            />
                        }
                    >
                        <FlexColumn>
                            {item.name}
                            {urlParts && (
                                <FlexRow style={{ width: "230px" }} title={item.url}>
                                    <Text
                                        style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            fontSize: "12px",
                                            color: "grey"
                                        }}
                                    >
                                        {urlParts[0]}
                                    </Text>
                                    <Text
                                        style={{
                                            whiteSpace: "nowrap",
                                            fontSize: "12px",
                                            color: "gray"
                                        }}
                                    >
                                        {urlParts[1]}
                                    </Text>
                                </FlexRow>
                            )}
                        </FlexColumn>
                    </TableCellLayout>
                )
            }
        }),
        createTableColumn<any>({
            columnId: "status",
            renderHeaderCell: () => (
                <Text>
                    {intl.formatMessage({
                        defaultMessage: "Status",
                        id: "status"
                    })}
                </Text>
            ),
            renderCell: (item) => <TableCellLayout>{item.status}</TableCellLayout>
        })
    ]
    const [columns] = useState(columnsDef)

    return (
        <PageContainer>
            <HeaderContainer>
                <TitleContainer>
                    <Apps24Regular />
                    <HeaderTitleText>
                        {intl.formatMessage({
                            defaultMessage: "Select Solution",
                            id: "selectSolution"
                        })}
                    </HeaderTitleText>
                    <ActionsContainer>
                        <IconButton
                            icon={<SearchRegular />}
                            onClick={toggleSearchBar}
                            data-testid={"bm-search-button"}
                        />
                    </ActionsContainer>
                </TitleContainer>
                <BreadcrumbWithBack />
            </HeaderContainer>
            <AnimatedSearchBar
                showSearchBar={showSearchBar}
                onChange={setSearchTerm}
                name={intl.formatMessage({ id: "solutions", defaultMessage: "Solutions" })}
            />
            <TableDisplay
                isLoading={isFetching}
                data={items}
                columns={columns}
                onClickCell={(item) => {
                    selectSolution(dispatch, item)
                    navigate(Paths.HomeDirect)
                }}
                hideFollowingColumns
            />
        </PageContainer>
    )
}

export const HeaderContainer = styled.div`
    background-color: ${tokens.colorNeutralBackground1};
    width: 100%;
    box-shadow: ${tokens.colorNeutralStroke1} 0px 1px 2px 2px;
    margin-bottom: 8px;
`

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px 8px 10px;
    height: 30px;
`
