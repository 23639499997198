import {
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableBody,
    TableCell,
    Text,
    TableColumnDefinition,
    Button,
    tokens,
    SkeletonItem
} from "@fluentui/react-components"
import styled, { keyframes } from "styled-components"
import { ErrorBoundary } from "react-error-boundary"
import { useState } from "react"
import { useIntl } from "react-intl"

interface TableDisplayProps {
    isLoading: boolean
    data: any[] | undefined
    /**
     * Must contain columnId, renderHeaderCell and renderCell.
     */
    columns?: TableColumnDefinition<any>[]
    onClickCell: (cellData: any) => void
    /**
     * Hide columns after the first one.
     */
    hideFollowingColumns?: boolean
    actions?: (item: any) => JSX.Element
    getNext?: () => void
    fetchingNext?: boolean
}

/**
 * @param isFetching Is data fetching, determines if skeleton should be displayed
 * @param data Data to display
 * @param columns Columns created with createTableColumn. Must contain columnId, renderHeaderCell and renderCell
 * @param onClickCell Function to call when a cell is clicked
 * @param hideFollowingColumns Hide columns after the first one
 * @returns Table display component
 */
export const TableDisplay = ({
    isLoading,
    data,
    columns,
    onClickCell,
    hideFollowingColumns,
    actions,
    getNext,
    fetchingNext
}: TableDisplayProps) => {
    const intl = useIntl()

    const TableContents = () => {
        if (data && data.length > 0) {
            return (
                <>
                    {data.map((item, index) => (
                        <TableRowStyled key={"tableRow" + index} onClick={() => onClickCell(item)}>
                            {columns?.map((column, index) => (
                                <TableCellStyled key={column.columnId + index.toString()}>
                                    {column.renderCell(item)}
                                </TableCellStyled>
                            ))}
                            {actions && (
                                <ButtonCellStyled key={"actionsButton"}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: "0",
                                            transform: "translateX(-10px) translateY(50%)"
                                        }}
                                    >
                                        {actions(item)}
                                    </div>
                                </ButtonCellStyled>
                            )}
                        </TableRowStyled>
                    ))}
                    {fetchingNext && (
                        <TableRowStyled key={"loadingRow"}>
                            <TableCell colSpan={columns?.length || 1}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <SkeletonItem
                                        shape="square"
                                        size={32}
                                        style={{ marginRight: "8px" }}
                                    />
                                    <SkeletonItem />
                                </div>
                            </TableCell>
                        </TableRowStyled>
                    )}
                </>
            )
        } else if (data?.length === 0) {
            return (
                <TableRowStyled>
                    <TableCell colSpan={columns?.length || 1}>
                        <Text
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "300px",
                                marginTop: "18px"
                            }}
                        >
                            {intl.formatMessage({ id: "noItems", defaultMessage: "No Items..." })}
                        </Text>
                    </TableCell>
                </TableRowStyled>
            )
        } else {
            throw new Error("Unexpected data error. Data object: " + data?.toString())
        }
    }

    const HeaderRow = () => (
        <TableRow style={{ borderBottomWidth: "0px" }}>
            {columns?.map((column, index) => (
                <TableHeaderCell
                    key={column.columnId}
                    style={{
                        width: hideFollowingColumns ? (index === 0 ? "300px" : "200px") : undefined,
                        position: "sticky",
                        top: "0",
                        backgroundColor: tokens.colorNeutralBackground1,
                        zIndex: 1,
                        boxShadow: `inset 0px -1px 0px 0px ${tokens.colorNeutralStroke1}`,
                        paddingBottom: "1px",
                        height: "42px",
                        paddingTop: "2px",
                        borderBottomWidth: "0px"
                    }}
                >
                    {column.renderHeaderCell()}
                </TableHeaderCell>
            ))}
        </TableRow>
    )

    const SkeletonTable = () => (
        <>
            <TableHeader>
                {columns && columns.length > 0 ? (
                    <HeaderRow />
                ) : (
                    <TableRow>
                        <TableHeaderCell key={"skeletonHeader1"}>
                            <SkeletonItem />
                        </TableHeaderCell>
                        <TableHeaderCell key={"skeletonHeader2"}>
                            <SkeletonItem />
                        </TableHeaderCell>
                        <TableHeaderCell key={"skeletonHeader3"}>
                            <SkeletonItem />
                        </TableHeaderCell>
                        <TableHeaderCell key={"skeletonHeader4"}>
                            <SkeletonItem />
                        </TableHeaderCell>
                    </TableRow>
                )}
            </TableHeader>
            <TableBody>
                {Array.from({ length: 15 }).map((_, index) => (
                    <TableRowStyled key={"skeletonRow" + index}>
                        <TableCell colSpan={columns?.length || 4}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <SkeletonItem
                                    shape="square"
                                    size={32}
                                    style={{ marginRight: "8px" }}
                                />
                                <SkeletonItem />
                            </div>
                        </TableCell>
                    </TableRowStyled>
                ))}
            </TableBody>
        </>
    )

    const TableErrorBoundary = ({ children }: { children: React.ReactNode }) => {
        const [showFullError, setShowFullError] = useState(false)
        const toggleErrorDisplay = () => setShowFullError(!showFullError)

        return (
            <ErrorBoundary
                fallbackRender={({ error }) => {
                    return (
                        <>
                            {columns && columns.length > 0 && (
                                <TableHeader>
                                    <HeaderRow />
                                </TableHeader>
                            )}
                            <TableBody>
                                <TableRowStyled>
                                    <Container>
                                        {intl.formatMessage({
                                            id: "somethingWentWrong",
                                            defaultMessage: "Something went wrong."
                                        })}
                                        {!showFullError && (
                                            <Button
                                                onClick={toggleErrorDisplay}
                                                appearance="transparent"
                                            >
                                                {intl.formatMessage({
                                                    id: "showMore",
                                                    defaultMessage: "Show more..."
                                                })}
                                            </Button>
                                        )}
                                        {showFullError && (
                                            <>
                                                <Button
                                                    onClick={toggleErrorDisplay}
                                                    appearance="transparent"
                                                >
                                                    {intl.formatMessage({
                                                        id: "showLess",
                                                        defaultMessage: "Show less..."
                                                    })}
                                                </Button>
                                                <div style={{ margin: "0px 10px" }}>
                                                    <Text>{error.toString()}</Text>
                                                </div>
                                            </>
                                        )}
                                    </Container>
                                </TableRowStyled>
                            </TableBody>
                        </>
                    )
                }}
            >
                {children}
            </ErrorBoundary>
        )
    }

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (getNext) {
            const bottom =
                e.currentTarget.scrollHeight - e.currentTarget.scrollTop <
                e.currentTarget.clientHeight + 100
            if (bottom) {
                getNext()
            }
        }
    }

    return (
        <TableContainer onScroll={handleScroll}>
            <Table
                arial-label="List table"
                style={isLoading || hideFollowingColumns ? {} : { width: "auto", minWidth: "100%" }}
            >
                {isLoading || !data ? (
                    <SkeletonTable />
                ) : (
                    <TableErrorBoundary>
                        <TableHeader>
                            <HeaderRow />
                        </TableHeader>
                        <TableBody>
                            <TableContents />
                        </TableBody>
                    </TableErrorBoundary>
                )}
            </Table>
        </TableContainer>
    )
}

const TableContainer = styled.div`
    overflow-x: scroll;
    scrollbar-width: none;
    width: calc(100% - 16px);
    max-height: 100%;
    margin: 0px 8px 8px 8px;
    background-color: ${tokens.colorNeutralBackground1};
    border-radius: 5px;
    box-shadow: ${tokens.colorNeutralStroke1} 0px 1px 2px 2px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 18px;
`

const slideIn = keyframes`
    from {
        transform: translateX(62px) translateY(-50%);
    }
    to {
        transform: translateX(0) translateY(-50%);
    }
`

const slideOut = keyframes`
    from {
        transform: translateX(0) translateY(-50%);
    }
    to {
        transform: translateX(40px) translateY(-50%);
    }
`

const ButtonCellStyled = styled.td`
    position: sticky;
    right: 0;
    visibility: hidden;
    padding: 0;
    /* transition: visibility 0.2s; */
    /* animation: ${slideOut} 0.2s forwards; */
`

const TableRowStyled = styled(TableRow)`
    background-color: none;
    position: relative;
    &:hover ${ButtonCellStyled} {
        visibility: visible;
        animation: ${slideIn} 0.2s forwards;
    }
`

const TableCellStyled = styled(TableCell)`
    position: relative;
    text-align: left;
    z-index: 0;
`
