import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { Provider } from "react-redux"
import { setupStore } from "./store/store"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const store = setupStore()
const persistor = persistStore(store)

initializeFileTypeIcons()

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={<div>LOADING STATE</div>}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
)
