import { useEffect, useState } from "react"
import { Express365Dispatch, useExpress365Dispatch } from "../store/store"
import { setMessages } from "../store/MessageReducer"
import { useGetSolutionsQuery } from "../service/WorkPointWebApi"
import { safeLog } from "../util/debug"
import { showNotification } from "../store/NotificationReducer"
import {
    createNestablePublicClientApplication,
    IPublicClientApplication
} from "@azure/msal-browser"
import { msalConfig } from "../auth"

let pca: IPublicClientApplication | undefined = undefined

export const useAppContext = (
    officeReady: React.MutableRefObject<boolean>,
    lang?: React.MutableRefObject<string>
) => {
    const dispatch = useExpress365Dispatch()

    const [pcaInitialized, setPcaInitialized] = useState(false)

    const { tenantUrl } = useGetSolutionsQuery(undefined, {
        skip: !pcaInitialized,
        selectFromResult: ({ data }) => {
            if (!data || data.length === 0) return { tenantUrl: null }
            return { tenantUrl: data?.[0].url! }
        }
    })

    useEffect(() => {
        if (!officeReady.current) {
            officeReady.current = true
            Office.onReady(() => {
                const initializePca = async () => {
                    pca = await createNestablePublicClientApplication(msalConfig)
                    await pca.initialize()
                    setPcaInitialized(true)
                }

                initializePca()

                // Register event handlers and additional Office initialization logic.
                Office?.context?.mailbox?.addHandlerAsync(
                    Office.EventType.SelectedItemsChanged,
                    (argu: any) => {
                        getSelectedItems(dispatch)
                    },
                    (asyncResult) => {
                        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                            console.error(
                                "Failed to add SelectedItemsChanged handler:",
                                asyncResult.error.message
                            )
                        } else {
                            safeLog("SelectedItemsChanged Event handler added.")
                        }
                    }
                )

                /**
                 * Set the selecetd email messages on app start, if selection made before app initialization.
                 */
                getSelectedItems(dispatch)
            })
        }
    }, [officeReady, dispatch])

    return { authorized: pcaInitialized, hasTenant: !!tenantUrl }
}

const getSelectedItems = (dispatch: Express365Dispatch) => {
    Office?.context?.mailbox?.getSelectedItemsAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            let translatedMessage: string
            switch (Office?.context?.displayLanguage?.split("-")[0]) {
                case "da":
                    translatedMessage =
                        "Kunne ikke hente den valgte e-mail. Det gav følgende fejl: "
                    break
                default:
                    translatedMessage =
                        "Could not get selected email message. It threw the following error: "
            }
            dispatch(
                showNotification({
                    message: translatedMessage + asyncResult.error.message,
                    intent: "error"
                })
            )
            return
        }

        let selectedItems = asyncResult.value

        if (selectedItems && selectedItems.length > 0) {
            selectedItems = selectedItems?.map((message) => {
                return {
                    ...message,
                    itemId: Office.context.mailbox.convertToRestId(
                        message.itemId,
                        Office.MailboxEnums.RestVersion.v2_0
                    )
                }
            })
        }

        dispatch(setMessages(selectedItems))
    })
}

export { pca }
