export enum SiteStatus {
    Provisioning = "*Provisioning*",
    IgnoreCreateSiteOnItemAddedEvent = "*IgnoreCreateSiteOnItemAddedEvent*",
    Failed = "*Failed*",
    FrontEndQueryFailure = "*FrontEndQueryFailure*",
    CreateSiteOnItemAddedEvent = "*CreateSiteOnItemAddedEvent*",
    WaitingForParentSite = "*WaitingForParentSite*",
    Empty = ""
}

const nonActiveSiteStatuses = [
    SiteStatus.Provisioning.toLowerCase(),
    SiteStatus.IgnoreCreateSiteOnItemAddedEvent.toLowerCase(),
    SiteStatus.Failed.toLowerCase(),
    SiteStatus.FrontEndQueryFailure.toLowerCase(),
    SiteStatus.CreateSiteOnItemAddedEvent.toLowerCase(),
    SiteStatus.WaitingForParentSite.toLowerCase(),
    SiteStatus.Empty.toLowerCase()
]

export const isActiveEntitySite = (entitySiteValueCandidate: string) => {
    if (typeof entitySiteValueCandidate !== "string") return false

    return nonActiveSiteStatuses.indexOf(entitySiteValueCandidate.toLowerCase().trim()) === -1
        ? true
        : false
}
