import { useNavigate, useRouteError } from "react-router"
import { ErrorOverlay } from "./ErrorOverlay"
import { ErrorType } from "../model/ErrorType"
import { ArrowClockwiseRegular, ArrowLeftRegular, ArrowSwapRegular } from "@fluentui/react-icons"
import { Paths } from "../constants/routing"
import { useIntl } from "react-intl"

export const RouteErrorHandler = () => {
    const intl = useIntl()
    const caughtError = useRouteError() as any
    const navigate = useNavigate()

    let message = intl.formatMessage({
        id: "unexpectedError",
        defaultMessage: "An unexpected error occurred."
    })
    let description = caughtError?.message
    let type = ErrorType.Unexpected
    let links = [
        {
            text: intl.formatMessage({
                id: "goBack",
                defaultMessage: "Go back"
            }),
            onClick: () => navigate(-1),
            icon: <ArrowLeftRegular />
        },
        {
            text: intl.formatMessage({
                id: "tryAgain",
                defaultMessage: "Try again"
            }),
            url: "",
            icon: <ArrowClockwiseRegular />
        },
        {
            text: intl.formatMessage({
                id: "changeSolution",
                defaultMessage: "Change Solution"
            }),
            onClick: () => navigate(Paths.Solutions),
            icon: <ArrowSwapRegular />
        }
    ]

    let json
    try {
        json = JSON.parse(caughtError.message)
    } catch {}

    if (json && json.message && json.type !== undefined) {
        return (
            <ErrorOverlay
                message={json.message}
                type={json.type}
                description={json.description}
                links={json.links}
            />
        )
    }

    if (caughtError?.data) {
        if (
            caughtError.data.ExceptionType ===
            "Microsoft.SharePoint.Client.ServerUnauthorizedAccessException"
        ) {
            message = intl.formatMessage({
                id: "unauthorized",
                defaultMessage: "Unauthorized"
            })
            description = intl.formatMessage({
                id: "unauthorizedDescription",
                defaultMessage:
                    "You are not authorized to view this page. Please contact your administrator."
            })
            type = ErrorType.Unauthorized
        } else {
            description = caughtError.data.ExceptionMessage
        }
    } else if (caughtError?.Message && caughtError?.ExceptionMessage) {
        message = caughtError.Message
        description = caughtError.ExceptionMessage
    } else if (caughtError?.error && typeof caughtError.error === "string") {
        description = caughtError.error
    }

    return <ErrorOverlay message={message} description={description} type={type} links={links} />
}
