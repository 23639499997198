import { createSlice } from "@reduxjs/toolkit"
import { AsyncModelState } from "../model/AsyncModelState"
import { Solution } from "../model/Solution"
import { workPointWebApi } from "../service/WorkPointWebApi"
import { resetBusinessModules } from "./BusinessModuleReducer"
import { Express365Dispatch } from "./store"

export type SolutionState = AsyncModelState & {
    solution: Solution | undefined
}

const initialState: SolutionState = {
    solution: undefined,
    status: undefined
}

const solutionSlice = createSlice({
    name: "solution",
    initialState,
    reducers: {
        clearSolution: (state) => {
            state.solution = undefined
        },
        setSolution: (state, action) => {
            state.solution = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        }
    }
})

const { setSolution, clearSolution } = solutionSlice.actions

export const solutionReducer = solutionSlice.reducer

export const selectSolution = (dispatch: Express365Dispatch, solution: Solution) => {
    dispatch(setSolution(solution))
    dispatch(workPointWebApi.util.invalidateTags(["SolutionSpecifics"]))
    dispatch(resetBusinessModules())
}

export const clearSolutionAndInvalidateLicense = (dispatch: Express365Dispatch) => {
    dispatch(clearSolution())
    dispatch(workPointWebApi.util.invalidateTags(["SolutionSpecifics"]))
}
