import {
    Configuration,
    InteractionRequiredAuthError,
    LogLevel,
    RedirectRequest,
    SilentRequest
} from "@azure/msal-browser"
import { pca } from "./hooks/useAppContext"

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_WPAPI_CLIENT_ID!,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: document.location.origin + "/login.html" // Must be registered as "spa" type
    },
    cache: {
        cacheLocation: "localStorage" // needed to avoid "login required" error
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        console.info(message)
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                }
            }
        }
    }
}

export const getToken = async (scopes: string[]): Promise<string> => {
    const activeAccount = pca!.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = pca!.getAllAccounts()

    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */

        const authPayload: RedirectRequest = {
            scopes
        }

        await pca!.acquireTokenPopup(authPayload)

        return ""
    }

    const silentAuthPayload: SilentRequest = {
        scopes,
        account: activeAccount || accounts[0]
    }

    const authResult = await pca!.acquireTokenSilent(silentAuthPayload).catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            pca!.acquireTokenPopup({ scopes })
            return
        }
        return undefined
    })

    return authResult?.accessToken || ""
}
