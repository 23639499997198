import { useLocation } from "react-router"
import { useExpress365Selector } from "../store/store"
import { useEffect } from "react"
import { appInsights } from "../appInsights"

export const useTelemetry = () => {
    const { solution } = useExpress365Selector((state) => state.solution)
    const location = useLocation()

    useEffect(() => {
        if (solution?.url) {
            appInsights.trackPageView({
                name: location.pathname + location.search,
                properties: { "WorkPoint Solution": solution?.url }
            })
        }
    }, [location, solution])
}
