// Layout.tsx
import React from "react"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { ProcessDrawer } from "./ProcessDrawer"
import { NotificationBar } from "./NotificationBar"
import { ToastProvider } from "./ToastProvider"
import { useNotificationListener } from "./NotificationListener"
import { useTelemetry } from "../hooks/useTelemetry"

export const Layout = () => {
    useNotificationListener()
    useTelemetry()

    return (
        <>
            <Outlet />
            <NotificationBar />
            <ActionsContainer>
                <ProcessDrawer />
                <ToastProvider />
            </ActionsContainer>
        </>
    )
}

const ActionsContainer = styled.div`
    position: fixed;
    bottom: 10px;
    right: 10px;
`
