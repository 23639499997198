import {
    Apps24Regular,
    AppsRegular,
    CalendarRegular,
    Calendar24Regular,
    LibraryRegular,
    Library24Regular,
    ListBarRegular,
    WindowMultipleRegular
} from "@fluentui/react-icons"
import { BreadcrumbWithBack } from "../components/BreadcrumbWithBack"
import { HeaderContainer, TitleContainer } from "./SolutionsPage"
import {
    Avatar,
    MessageBar,
    MessageBarBody,
    MessageBarIntent,
    MessageBarTitle,
    TableCellLayout,
    TableColumnDefinition,
    Text,
    createTableColumn
} from "@fluentui/react-components"
import { PageContainer } from "./Home"
import { generatePath, useNavigate, useParams } from "react-router"
import { useGetEntitiesQuery, useGetListsAndLibrariesQuery } from "../service/SharePointWebApi"
import { useState } from "react"
import { Paths } from "../constants/routing"
import { Entity } from "../model/Item"
import { ListTemplateType } from "../constants/ListTemplateType"
import { useIntl } from "react-intl"
import { TableDisplay } from "../components/TableDisplay"
import { Actions } from "../components/Actions"
import { ListAndLibrary } from "../model/ListOrLibrary"
import { ActionsContainer, HeaderTitleText } from "./BusinessModulePage"
import { useContextDeltaCheck } from "../hooks/useContextDeltaCheck"
import { isActiveEntitySite } from "../constants/SiteStatus"

export const EntityPage = () => {
    const intl = useIntl()
    const params = useParams()
    const { businessModuleId, entityId } = params as { businessModuleId: string; entityId: string }
    const { entity }: { entity: Entity } = useGetEntitiesQuery(
        { listId: businessModuleId },
        {
            selectFromResult: ({ data }) => ({
                entity: data?.ListData.Row.find((item) => item.ID === entityId)
            })
        }
    )

    const columnsDef: TableColumnDefinition<any>[] = [
        createTableColumn<any>({
            columnId: "Title",
            renderHeaderCell: () => (
                <Text weight="semibold">
                    {intl.formatMessage({
                        defaultMessage: "Title",
                        id: "title"
                    })}
                </Text>
            ),
            renderCell: (item) => (
                <TableCellLayout
                    media={getListIcon(item.BaseTemplate)}
                    description={getListTypeName(item.BaseTemplate)}
                    content={{ style: { alignItems: "start" } }}
                >
                    {item.Title}
                </TableCellLayout>
            )
        })
    ]
    const [columns] = useState(columnsDef)

    const {
        data: lists,
        isFetching,
        isError,
        error
    } = useGetListsAndLibrariesQuery(entity?.wpSite, {
        skip: !entity || !isActiveEntitySite(entity?.wpSite)
    })

    let errorMessage: string = ""
    if (isError) {
        errorMessage = (error as any)?.data?.error?.message?.value
    }

    const navigate = useNavigate()

    useContextDeltaCheck({ listId: businessModuleId, itemId: entityId }, null, 30000)

    return (
        <PageContainer>
            <HeaderContainer>
                <TitleContainer>
                    {entity && (
                        <>
                            <Avatar
                                color="colorful"
                                name={entity.Title}
                                style={{ borderRadius: "0px" }}
                            />
                            <HeaderTitleText>{entity.Title}</HeaderTitleText>
                        </>
                    )}
                    <ActionsContainer>
                        <Actions />
                    </ActionsContainer>
                </TitleContainer>
                <BreadcrumbWithBack />
            </HeaderContainer>
            {/**
             * @todo: Entity Presentation should be shown here when we have it.
             */}
            {!isActiveEntitySite(entity?.wpSite) && (
                <StyledMessageBar
                    intent="info"
                    title={intl.formatMessage({
                        defaultMessage: "Site Not Found",
                        id: "siteNotFound"
                    })}
                    message={`The entity '${entity?.Title}' does not have a site`}
                />
            )}
            {isError ? (
                <StyledMessageBar
                    intent="error"
                    title={intl.formatMessage({
                        defaultMessage: "Access Denied",
                        id: "accessDenied"
                    })}
                    message={errorMessage}
                />
            ) : (
                entity &&
                isActiveEntitySite(entity.wpSite) && (
                    <TableDisplay
                        isLoading={isFetching}
                        data={lists}
                        columns={columns}
                        onClickCell={(item: ListAndLibrary) => {
                            navigate(
                                generatePath(Paths.EntityListView, {
                                    businessModuleId,
                                    entityId,
                                    listId: item.Id,
                                    viewId: item.DefaultView?.Id
                                })
                            )
                        }}
                        actions={(item: ListAndLibrary) => (
                            <Actions entityListOrLibraryId={item.Id} showPinnedRowButtons />
                        )}
                    />
                )
            )}
        </PageContainer>
    )
}

const StyledMessageBar = ({
    intent,
    title,
    message
}: {
    intent: MessageBarIntent
    title: string
    message?: string
}) => {
    const intl = useIntl()
    return (
        <div style={{ width: "calc(100% - 10px)", padding: "0px 5px" }}>
            <MessageBar intent={intent}>
                <MessageBarBody style={{ textWrap: "wrap", textAlign: "left" }}>
                    <MessageBarTitle>{title}</MessageBarTitle>
                    {message
                        ? message
                        : intl.formatMessage({
                              id: "unexpectedError",
                              defaultMessage: "An unexpected error occurred."
                          })}
                </MessageBarBody>
            </MessageBar>
        </div>
    )
}

export const getListIcon = (baseTemplateType: number, large?: boolean) => {
    switch (baseTemplateType) {
        case ListTemplateType.GenericList:
            return large ? <ListBarRegular style={{ fontSize: "24px" }} /> : <ListBarRegular />
        case ListTemplateType.DocumentLibrary:
            return large ? <Library24Regular /> : <LibraryRegular />
        case ListTemplateType.Events:
            return large ? <Calendar24Regular /> : <CalendarRegular />
        case ListTemplateType.WebPageLibrary:
            return large ? (
                <WindowMultipleRegular style={{ fontSize: "24px" }} />
            ) : (
                <WindowMultipleRegular />
            )
        default:
            return large ? <Apps24Regular /> : <AppsRegular />
    }
}

const getListTypeName = (baseTemplateType: number) => {
    switch (baseTemplateType) {
        case ListTemplateType.GenericList:
            return "List"
        case ListTemplateType.DocumentLibrary:
            return "Document library"
        case ListTemplateType.Events:
            return "Events"
        case ListTemplateType.WebPageLibrary:
            return "Web page library"
    }
}
