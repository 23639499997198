import React, { useRef } from "react"
import "./App.css"
import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom"
import { Home } from "./pages/Home"
import { SolutionsPage } from "./pages/SolutionsPage"
import { Paths } from "./constants/routing"
import { BusinessModulePage } from "./pages/BusinessModulePage"
import { EntityPage } from "./pages/EntityPage"
import { AppNotifications } from "./components/AppNotifications"
import { useAppContext } from "./hooks/useAppContext"
import { EntityListPage } from "./pages/EntityListPage"
import { IntlProvider } from "react-intl"
import { Layout } from "./components/Layout"
import { StartUp } from "./pages/StartUpPage"
import { ErrorOverlay } from "./components/ErrorOverlay"
import { useExpress365Dispatch } from "./store/store"
import { useGetUserLicenseQuery } from "./service/WorkPointWebApi"
import { ErrorType, ExpressError } from "./model/ErrorType"
import { clearSolutionAndInvalidateLicense } from "./store/SolutionReducer"
import { ArrowSwapRegular } from "@fluentui/react-icons"
import { RouteErrorHandler } from "./components/RouteErrorHandler"
import { reactPlugin } from "./appInsights"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import { FluentProvider, webDarkTheme, webLightTheme } from "@fluentui/react-components"

function App() {
    /**
     * Guard to make sure Office.onReady is only called once.
     * React Strict mode forces the app to render twice in development mode.
     */
    const officeReadyCalled = useRef(false)

    const { authorized, hasTenant } = useAppContext(officeReadyCalled)

    const { data: userLicense, isFetching: fetchingLicense } = useGetUserLicenseQuery(undefined, {
        skip: !authorized
    })
    const dispatch = useExpress365Dispatch()

    // Internal App Routing
    const router = createBrowserRouter(routes)

    let messages

    switch (Office?.context?.displayLanguage?.split("-")[0] || "en") {
        case "da":
            messages = require("./locales/da-DK.json")
            break
        default:
            messages = require("./locales/en.json")
    }

    const loading = !authorized || !hasTenant || fetchingLicense

    let userLicenseError: ExpressError | undefined = undefined
    if (userLicense?.SolutionUrl && userLicense?.Status !== 1) {
        userLicenseError = {
            type: ErrorType.License,
            message: "⚠️ No WorkPoint License available for this solution.",
            description: "Contact your administrator to request a license.",
            links: [
                {
                    text: "Change Solution",
                    onClick: () => clearSolutionAndInvalidateLicense(dispatch),
                    icon: <ArrowSwapRegular />
                }
            ]
        }
    }

    /**
     * @warning: Use the <Layout> component if you need to wrap location changes in a layout.
     */
    return (
        <FluentProvider
            theme={
                Office?.context?.officeTheme?.controlBackgroundColor === "#292929"
                    ? webDarkTheme
                    : webLightTheme
            }
        >
            <IntlProvider
                defaultLocale="en"
                messages={messages}
                locale={Office?.context?.displayLanguage || "en"}
            >
                <div
                    className="App"
                    data-testid="express-container"
                    style={{
                        height: "100vh",
                        width: "100vw"
                    }}
                >
                    {loading ? (
                        <StartUp
                            authorized={authorized}
                            hasTenant={hasTenant}
                            fetchingLicense={fetchingLicense}
                        />
                    ) : userLicenseError ? (
                        <ErrorOverlay {...userLicenseError} />
                    ) : (
                        <RouterProvider router={router} />
                    )}
                    <AppNotifications />
                </div>
            </IntlProvider>
        </FluentProvider>
    )
}

const routes: RouteObject[] = [
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: Paths.Solutions,
                element: <SolutionsPage />
            },
            {
                path: Paths.BusinessModules,
                element: <BusinessModulePage />
            },
            {
                path: Paths.Entity,
                element: <EntityPage />
            },
            {
                path: Paths.EntityListView,
                element: <EntityListPage />
            },
            {
                path: Paths.EntityListViewFolder,
                element: <EntityListPage />
            }
        ],
        errorElement: <RouteErrorHandler />
    }
]

export default withAITracking(reactPlugin, App)
