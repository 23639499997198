import { useGetMyToolsQuery, useInitializeUserProcessMutation } from "../service/WorkPointWebApi"
import { DismissRegular, MoreHorizontalRegular } from "@fluentui/react-icons"
import { useExpress365Selector } from "../store/store"
import {
    Button,
    Divider,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Text
} from "@fluentui/react-components"
import { useParams } from "react-router"
import {
    IMyToolsButtonSetting,
    IMyToolsLinkButtonSetting,
    IMyToolsSettings,
    IMyToolsUserProcessButtonSetting,
    isMyToolsLink,
    isMyToolsUserProcess,
    MyToolsButtonType,
    MyToolsParameters
} from "../model/MyTools"
// import { useGetEntitiesQuery } from "../service/SharePointWebApi"
// import { Entity } from "../model/Item"
import { useState } from "react"
import { BusinessModuleIcon } from "./BusinessModuleIcon"
import { FlexRow } from "./ProcessDrawer"
import { Solution } from "../model/Solution"
import { OutlookMessage } from "../store/MessageReducer"
import { useToast } from "./ToastProvider"
import { useIntl } from "react-intl"

export const Actions: React.FC<{
    /**
     * Entity item id from selection, not route.
     */
    entityItemId?: number
    /**
     * List or library from selection, not route.
     */
    entityListOrLibraryId?: string
    /**
     * Folder path from selection, not route.
     */
    libraryOrListFolderPath?: string
    showPinnedRowButtons?: boolean
}> = ({ entityItemId, entityListOrLibraryId, libraryOrListFolderPath, showPinnedRowButtons }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const {
        businessModuleId: routeBusinessModuleId,
        entityId: routeEntityId,
        listId: routeEntityListOrLibraryId,
        folderPath: routeFolderPath
    } = useParams()

    const intl = useIntl()

    const { showSimpleToast } = useToast()

    // Route segments
    let businessModuleId = routeBusinessModuleId,
        entityId = routeEntityId,
        entityListId = routeEntityListOrLibraryId,
        folderPath = routeFolderPath

    /**
     * If a business module is selected, and an entity is the direct target of the users click, then assign the entity id.
     */
    if (businessModuleId && entityItemId) {
        entityId = entityItemId.toString()
    }

    /**
     * If a list or library is the direct target of the users click, then assign the list or library id.
     */
    if (businessModuleId && entityId && entityListOrLibraryId) {
        entityListId = entityListOrLibraryId
    }

    /**
     * If a folder is the direct target of the users click, then assign the folder path.
     */
    if (libraryOrListFolderPath) {
        folderPath = libraryOrListFolderPath
    }

    /**
     * @todo: We can use the 'isLoading' to show a process is starting, maybe with a spinner.
     */
    const [runProcess /*, { isLoading }*/] = useInitializeUserProcessMutation()
    const { selectedMessages } = useExpress365Selector((state) => state.message)
    const selectedMessageIds = selectedMessages?.map((m) => ({ itemId: m.itemId }))

    const solution = useExpress365Selector((state) => state.solution.solution)

    /**
     * @todo: If we require using Processes.
     */
    // const { data: processes } = useGetProcessesQuery(undefined, { skip: !solution })

    const { myTools }: { myTools: IMyToolsSettings } = useGetMyToolsQuery(undefined, {
        skip: !solution,
        selectFromResult: ({ data }) => {
            const filterOnBusinessModule = entityId && businessModuleId ? businessModuleId : null
            return {
                myTools: data?.find(
                    (tool: IMyToolsSettings) => tool.BusinessModuleId === filterOnBusinessModule
                )!
            }
        }
    })

    const usableToolsGroups = myTools?.Groups?.filter((group) =>
        group.Buttons.some((b) => b.DisplayInApps && b.DisplayInApps.indexOf("Express365") !== -1)
    )

    let pinnedRowButtons: IMyToolsUserProcessButtonSetting | IMyToolsButtonSetting[] = []

    if (showPinnedRowButtons) {
        if (usableToolsGroups && usableToolsGroups.length > 0) {
            for (const gr of usableToolsGroups) {
                for (const b of gr.Buttons) {
                    if (b.PinToToolbar) {
                        pinnedRowButtons.push(
                            b as IMyToolsUserProcessButtonSetting | IMyToolsLinkButtonSetting
                        )
                    }
                }
            }
        }
    }

    // /**
    //  * @todo: If we require using metadata.
    //  */
    // const { entity }: { entity: Entity } = useGetEntitiesQuery(
    //     { listId: businessModuleId! },
    //     {
    //         selectFromResult: ({ data }) => ({
    //             entity: data?.find((item) => item.ID === entityId)
    //         })
    //     }
    // )

    return (
        <FlexRow>
            {/**
             * Conditionally showing the attachments button inline on data rows. Only applicable for Table displays.
             */}
            {pinnedRowButtons.length > 0 &&
                pinnedRowButtons.map((tool, index) => {
                    if (isMyToolsUserProcess(tool)) {
                        return (
                            <Button
                                key={`mytools-row-button-${index}`}
                                style={{ borderWidth: "0px", marginRight: "4px" }}
                                icon={<BusinessModuleIcon iconUrl={tool.Icon} />}
                                title={tool.Title}
                                onClick={(e) => {
                                    e.stopPropagation()

                                    if (tool.ProcessExecutionType === "Interactive") {
                                        const processParameters = getUserProcessParameters(
                                            tool as IMyToolsUserProcessButtonSetting
                                        )

                                        openDialog(
                                            solution!.url,
                                            tool.ProcessId!,
                                            undefined,
                                            businessModuleId,
                                            entityId,
                                            processParameters,
                                            selectedMessageIds
                                        )
                                    } else if (tool.ProcessExecutionType === "NonInteractive") {
                                        /**
                                         * @todo: Handle Process Parameters from MyTools settings and override with selections.
                                         */
                                        runProcess({
                                            processId: tool.ProcessId!,
                                            processArguments: {
                                                businessModuleId: businessModuleId!,
                                                entityId: parseInt(entityId!),
                                                items: [],
                                                Express:
                                                    selectedMessageIds &&
                                                    selectedMessageIds.length > 0
                                                        ? selectedMessageIds
                                                        : undefined,
                                                parameters: {
                                                    client: "express",
                                                    EntitySiteList: entityListId,
                                                    EntitySiteListFolder: folderPath
                                                }
                                            }
                                        })
                                        showSimpleToast(
                                            "Action started",
                                            `Triggered by ${tool!.Title}`
                                        )
                                    }
                                }}
                                size="small"
                            />
                        )
                    } else if (isMyToolsLink(tool)) {
                        return (
                            <Button
                                key={`mytools-row-button-${index}`}
                                icon={<BusinessModuleIcon iconUrl={tool.Icon} />}
                                style={{ borderWidth: "0px", marginRight: "4px" }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    window.open(tool.Url, tool.Target)
                                }}
                                title={tool.Title}
                                size="small"
                            />
                        )
                    } else return null
                })}
            <Menu
                open={menuOpen}
                onOpenChange={(e, data) => {
                    setMenuOpen(data.open)
                }}
            >
                <MenuTrigger disableButtonEnhancement>
                    <Button
                        style={{ borderWidth: "0px" }}
                        icon={<MoreHorizontalRegular />}
                        onClick={(e) => {
                            setMenuOpen(!menuOpen)
                            e.stopPropagation()
                        }}
                        size="small"
                    />
                </MenuTrigger>
                <MenuPopover style={{ minWidth: "320px" }}>
                    <div
                        style={{
                            display: "flex",
                            paddingLeft: "6px",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Text weight="semibold">
                            {intl.formatMessage({
                                defaultMessage: "Options",
                                id: "options"
                            })}
                        </Text>
                        <Button
                            style={{ borderWidth: "0px" }}
                            icon={<DismissRegular />}
                            onClick={(e: any) => {
                                setMenuOpen(false)
                                e.stopPropagation()
                            }}
                        />
                    </div>
                    <Divider style={{ marginLeft: "-10px", width: "330px" }} />
                    <MenuList>
                        {usableToolsGroups &&
                            usableToolsGroups.map((group, groupIndex) => (
                                <Menu key={`mytools-group-${groupIndex}`} positioning={"below"}>
                                    <MenuTrigger disableButtonEnhancement>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation()
                                            }}
                                            style={{ minWidth: "310px" }}
                                        >
                                            {group.GroupTitle}
                                            {/* @todo: Use translated title */}
                                        </MenuItem>
                                    </MenuTrigger>

                                    <MenuPopover style={{ minWidth: "320px" }}>
                                        <MenuList>
                                            {group.Buttons.filter(
                                                (tool) =>
                                                    tool.DisplayInApps &&
                                                    tool.DisplayInApps.indexOf("Express365") !==
                                                        -1 &&
                                                    (tool.Type === MyToolsButtonType.Link ||
                                                        tool.Type === MyToolsButtonType.UserProcess)
                                            ).map((tool, buttonIndex) => {
                                                if (isMyToolsUserProcess(tool)) {
                                                    return (
                                                        <ProcessButton
                                                            key={`mytools-group-${groupIndex}-button-${buttonIndex}`}
                                                            tool={tool}
                                                            selectedMessageIds={selectedMessageIds}
                                                            solution={solution!}
                                                            businessModuleId={businessModuleId!}
                                                            entityId={entityId!}
                                                            entityListId={entityListId}
                                                            entitySiteListFolder={folderPath}
                                                        />
                                                    )
                                                } else if (isMyToolsLink(tool)) {
                                                    return (
                                                        <MenuItem
                                                            key={`mytools-group-${groupIndex}-button-${buttonIndex}`}
                                                            icon={
                                                                <BusinessModuleIcon
                                                                    iconUrl={tool.Icon}
                                                                />
                                                            }
                                                            style={{
                                                                borderWidth: "0px",
                                                                justifyContent: "left"
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                window.open(tool.Url, tool.Target)
                                                            }}
                                                        >
                                                            {tool.Title}
                                                        </MenuItem>
                                                    )
                                                } else return null
                                            })}
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            ))}
                        {(!usableToolsGroups ||
                            (usableToolsGroups && usableToolsGroups.length === 0)) && (
                            <MenuItem>
                                {intl.formatMessage({
                                    defaultMessage: "No tools available",
                                    id: "noToolsAvailable"
                                })}
                            </MenuItem>
                        )}
                    </MenuList>
                    {/**
                     * @todo: Deferred until we have Entity Presentations available
                     * Must only be shown when we have businessModuleId and entityId from the route.
                     */}
                    {/* <Button appearance="subtle" icon={<InfoRegular />}>
                        <Text>
                            {intl.formatMessage({
                                defaultMessage: "Properties",
                                id: "properties"
                            })}
                        </Text>
                    </Button> */}
                </MenuPopover>
            </Menu>
        </FlexRow>
    )
}

const ProcessButton: React.FC<{
    tool: IMyToolsUserProcessButtonSetting
    selectedMessageIds: { itemId: string }[]
    solution: Solution
    businessModuleId: string
    entityId: string
    entityListId?: string
    entitySiteListFolder?: string
}> = ({
    tool,
    selectedMessageIds,
    solution,
    businessModuleId,
    entityId,
    entityListId,
    entitySiteListFolder
}) => {
    const [runProcess /*, { isLoading }*/] = useInitializeUserProcessMutation()

    const { showSimpleToast } = useToast()
    return (
        <MenuItem
            icon={<BusinessModuleIcon iconUrl={tool.Icon} />}
            onClick={(e) => {
                e.stopPropagation()

                if (tool.ProcessExecutionType === "Interactive") {
                    const processParameters = getUserProcessParameters(
                        tool as IMyToolsUserProcessButtonSetting
                    )

                    openDialog(
                        solution!.url,
                        tool.ProcessId!,
                        undefined,
                        businessModuleId,
                        entityId,
                        processParameters,
                        selectedMessageIds
                    )
                } else if (tool.ProcessExecutionType === "NonInteractive") {
                    /**
                     * @todo: Handle Process Parameters from MyTools settings and override with selections.
                     */
                    runProcess({
                        processId: tool.ProcessId!,
                        processArguments: {
                            businessModuleId: businessModuleId!,
                            entityId: parseInt(entityId!),
                            items: [],
                            Express:
                                selectedMessageIds && selectedMessageIds.length > 0
                                    ? selectedMessageIds
                                    : undefined,
                            parameters: {
                                client: "express",
                                EntitySiteList: entityListId,
                                EntitySiteListFolder: entitySiteListFolder
                            }
                        }
                    })
                    showSimpleToast("Action started", `Triggered by ${tool!.Title}`)
                }
            }}
            style={{ minWidth: "310px" }}
        >
            {tool.Title}
        </MenuItem>
    )
}

/**
 * @todo: This must handle arguments set from the Actions.tsx component!
 * @param buttonSetting
 * @returns
 */
export const getUserProcessParameters = (
    buttonSetting: IMyToolsUserProcessButtonSetting
): MyToolsParameters => {
    let parameters: MyToolsParameters = {
        BusinessModuleContentType: buttonSetting.BusinessModuleContentType,
        BusinessModuleId: buttonSetting.BusinessModuleId,
        EntitySiteList: buttonSetting.EntitySiteList,
        EntitySiteListContentType: buttonSetting.EntitySiteListContentType,
        Folder: buttonSetting.Folder
    }

    const parameterKeys = Object.keys(buttonSetting.Parameters! || {})

    parameterKeys?.forEach((pk) => {
        parameters[pk] = buttonSetting.Parameters![pk]
    })

    return parameters
}

export const openDialog = async (
    sphostUrl: string,
    processId?: string,
    processInstanceId?: string,
    businessmoduleId?: string,
    entityId?: string,
    parameters?: {
        BusinessModuleId?: string
        BusinessModuleContentType?: string
        EntitySiteList?: string
        EntitySiteListContentType?: string
        Folder?: string
        StageId?: string
        [key: string]: any
    },
    express?: Partial<OutlookMessage>[]
) => {
    return new Promise<string>(async (resolve, reject) => {
        const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}/process`

        const queryParams = new URLSearchParams()
        queryParams.set("sphostUrl", sphostUrl)
        if (processId) queryParams.set("processid", processId)
        if (processInstanceId) queryParams.set("processInstanceId", processInstanceId)
        if (businessmoduleId) queryParams.set("businessmoduleid", businessmoduleId)
        if (entityId) queryParams.set("entityid", entityId)
        if (express) queryParams.set("express", JSON.stringify(express))

        if (parameters) {
            Object.keys(parameters).forEach((key) => {
                if (parameters[key]) queryParams.set(key, parameters[key])
            })
        }

        // Set client type
        queryParams.set("client", "express")

        const url = `${baseUrl}?${queryParams.toString()}`

        let dialog: Office.Dialog
        await Office.context.ui.displayDialogAsync(
            url,
            { height: 50, width: 50, promptBeforeOpen: false },
            (result: Office.AsyncResult<Office.Dialog>) => {
                dialog = result.value
                if (result.status === Office.AsyncResultStatus.Failed) {
                    console.warn(`${result.error.code} ${result.error.message}`)
                } else {
                    dialog = result.value
                    dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                        processMessage(arg, resolve, reject, dialog)
                    })
                    dialog.addEventHandler(
                        Office.EventType.DialogEventReceived,
                        finishOrCloseDialog
                    )
                }
            }
        )
    })
}

function processMessage(
    arg: any,
    resolve: (value: string) => void,
    reject: (reason: string) => void,
    dialog: Office.Dialog
) {
    const messageFromDialog = JSON.parse(arg.message)
    if (messageFromDialog.status === "success") {
        dialog.close()
        resolve(messageFromDialog.result)
    }
}
function finishOrCloseDialog(arg: any) {
    window.postMessage({
        type: "express365",
        action: "cache-invalidation-check"
    })
}
