export type LocalizationResource = {
    [key: string]: string
}

export const locales: LocalizationResource = {
    1025: "ar",
    1026: "bg",
    1027: "ca",
    1028: "zh",
    1029: "cs",
    1030: "da",
    1031: "de",
    1032: "el",
    1033: "en",
    1035: "fi",
    1036: "fr",
    1037: "he",
    1038: "hu",
    1040: "it",
    1041: "ja",
    1042: "ko",
    1043: "nl",
    1044: "nb",
    1045: "pl",
    1046: "pt",
    1048: "ro",
    1049: "ru",
    1050: "hr",
    1051: "sk",
    1053: "sv",
    1054: "th",
    1055: "tr",
    1057: "id",
    1058: "uk",
    1060: "sl",
    1061: "et",
    1062: "lv",
    1063: "lt",
    1066: "vi",
    1068: "az",
    1069: "eu",
    1071: "mk",
    1081: "hi",
    1086: "ms",
    1087: "kk",
    1106: "cy",
    1110: "gl",
    1164: "prs",
    2052: "zh",
    2070: "pt",
    2074: "sr",
    2108: "ga",
    3082: "es",
    5146: "bs",
    9242: "sr",
    10266: "sr"
}

/**
 * Given a string locale string, returns the matching SharePoint locale id.
 *
 * @default 1033
 *
 * @param {string} localeString
 */
export const getNumberLocaleFromStringLocale = (localeString: string): number => {
    try {
        const loweredLocaleString = localeString.toLowerCase().split("-")[0]

        const matches = Object.keys(locales).filter((key) => locales[key] === loweredLocaleString)

        if (matches && matches.length > 0) {
            return parseInt(matches[0])
        }

        throw new Error("No locale matching.")
    } catch (exception) {
        console.warn(
            `Could not get locale from string: '${localeString}'. It threw the following error: '${exception}'. Defaulting to en-US locale (1033) instead.`
        )
    }

    return 1033
}
