export enum ErrorType {
    Solution,
    Critical,
    License,
    Unexpected,
    Unauthorized
}

export interface ExpressError {
    type: ErrorType
    message: string
    description?: string
    links?: { text: string; icon?: any; url?: string; onClick?: () => void }[]
}
